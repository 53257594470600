import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`Migrating to newer versions of software might seem unimportant. These upgrades often require reworking existing infrastructure, which can be costly. However, the benefits of upgrading to PHP 7 outweigh the costs so significantly that you should not hesitate to do so. In fact, it would be irresponsible to continue without upgrading, as you’ll see below.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={4} colLg={8} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFqUtLkihX/xAAZEAEBAAMBAAAAAAAAAAAAAAABAgADESL/2gAIAQEAAQUCp5k7isFcrsyHmtZrf//EABYRAQEBAAAAAAAAAAAAAAAAAAABAv/aAAgBAwEBPwFdP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aaj/xAAeEAACAQMFAAAAAAAAAAAAAAAAAQIRIWEQEkFRcf/aAAgBAQAGPwLeuSUXbrI2pRWGxU0pZ+o//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITGR/9oACAEBAAE/IQQCPESpLjIoDVhvSNbcgFDLnsbD/9oADAMBAAIAAwAAABD4L//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQMBAT8QDZB5f//EABgRAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EHQSxp//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhUTFBkYH/2gAIAQEAAT8QEwGGi6bnZ4SUd8HkRSegBPYgraQqPe9weKBkHmyo5rRnMc/Z/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "woman-coding-on-computer",
              "title": "woman-coding-on-computer",
              "src": "/static/ba69de45472d76ed5cb84c40be2f3193/b1bff/media.jpg",
              "srcSet": ["/static/ba69de45472d76ed5cb84c40be2f3193/69549/media.jpg 288w", "/static/ba69de45472d76ed5cb84c40be2f3193/473e3/media.jpg 576w", "/static/ba69de45472d76ed5cb84c40be2f3193/b1bff/media.jpg 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
      </Column>
      <Column colMd={6} colLg={12} mdxType="Column">
        <br /><br />
        <AnchorLinks mdxType="AnchorLinks">
          <AnchorLink mdxType="AnchorLink">1. PHP 7.x is between 2 and 3 times faster than PHP 5.6</AnchorLink>
          <AnchorLink mdxType="AnchorLink">2. The new version uses a lot less memory and other resources</AnchorLink>
          <AnchorLink mdxType="AnchorLink">3. PHP 7 lets you catch bugs earlier</AnchorLink>
          <AnchorLink mdxType="AnchorLink">4. Caching has been significantly improved</AnchorLink>
          <AnchorLink mdxType="AnchorLink">5. Improved error handling means fewer bugs crash your site</AnchorLink>
          <AnchorLink mdxType="AnchorLink">6. 64-bit numbers for higher precision and bigger values</AnchorLink>
          <AnchorLink mdxType="AnchorLink">7. PHP 5.6 is no longer officially supported</AnchorLink>
          <AnchorLink mdxType="AnchorLink">8. Many hosting providers only offer PHP 7 support</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Conclusion</AnchorLink>
        </AnchorLinks>
        <h3>{`1. PHP 7.x is between 2 and 3 times faster than PHP 5.6`}</h3>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={6} colLg={8} mdxType="Column">
        <p>{`When you upgrade your application to any version in the 7.x series, you’ll probably notice major speed improvements. With PHP 7.4, which is the latest version as of this writing, your application might run up to `}<a parentName="p" {...{
            "href": "https://kinsta.com/blog/php-benchmarks/"
          }}>{`three times as fast`}</a>{`. That makes a huge difference in the number of servers required to run your application, saving you lots of money in the long run. `}</p>
        <br />
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem',
          width: "60%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "940px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "83.68055555555556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsSAAALEgHS3X78AAACfUlEQVQ4y5WTTUwTQRiGxxPRxBsar0bj3RuJieGiQdSDUdGL0UQ5qIgnEP8S8GJQATXRA4EgAgVjYkyMv4lFJCBGwdJyEaWLpbvttt2/duuW7c68zu4CQUL8+TL5MpmdZ97322+G4PdgzM1KikoxOhd10kmqpllGpv76iiCxWEzXdT6jlO9gPPETDM2Rfjiy6CTiTirhKCnH+7gyiKZplmXhH4J5vnjm5xeZm8mf9vq7GVb17NpWFCWf/7lUsEPdsXyyFLE8wgZ0GyMGuy+yNyojoigur9l3tzQcxgSDTWtMLbAdQyB9uDiF6mlKXtOdIbpgm3oKnwWc7MSFRwjFUfscZ5/hfQxbAiBtuBNB5RhIDxoiODfDSJDuiVBimmahUChS13XnO0Z2o/QYuj+ipB6kFu3jbGs/yE20htjeMVe5YYrDlMMVHBYEgZdNPfm+UUaOYFMNBibYumtYcwXdkx58C62Ti3AENd8XYf8/zdsu3TsKchgbzqB/AiWNIJc4DB9um8QyZUbeerBt2w4P+p+wr5xKxwdfJtqbrcHH+YfDjFS5cGCcLcChv9nuvm1uXy+f368+GGLkKDZ6ymubQC67ytsGQFrQFsa+sYVW1XrKlT4cuGeVlap1B7MdQUYOoPQUej+h5CpIHTq+YDNvVTNaQqjwWlUfRvUMSBDlEfd60qcBoe5EtPOGGAxbuxql43czH6LFqi75UFdmeNY+/Spd3iO/EOymcaXsidgfLXTMGhUj0vVvhqtsWTkjm8zlFce2cqpk5RR+tRQxoUoZU5tPzyULegq0aGqKmZJAbS1pzIbFdExb5WH49zmrQ5aQkVlWX/ladBXRr0iK+AWjrUd8OdAAYwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "wordpress-5-3-php-benchmarks",
              "title": "wordpress-5-3-php-benchmarks",
              "src": "/static/0c9ce79efcaccd836682195beefd2ab3/7ef15/wordpress-5-3-php-benchmarks.png",
              "srcSet": ["/static/0c9ce79efcaccd836682195beefd2ab3/7fc1e/wordpress-5-3-php-benchmarks.png 288w", "/static/0c9ce79efcaccd836682195beefd2ab3/a5df1/wordpress-5-3-php-benchmarks.png 576w", "/static/0c9ce79efcaccd836682195beefd2ab3/7ef15/wordpress-5-3-php-benchmarks.png 940w"],
              "sizes": "(max-width: 940px) 100vw, 940px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <h3>{`2. The new version uses a lot less memory and other resources`}</h3>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "975px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "42.36111111111111%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABcSAAAXEgFnn9JSAAABm0lEQVQoz12Sa0/bMBSG+5+nXQolgPhfk/ZhtKQdlZjYtGmMjdLSxEma2I5z59mpJ9jlw6v3+Fh+9PrYI2stxlhcPZDuDFFSEKkclebeozghSTO22y1RFBHHsa+VUjxsNmRZRlVVOOcwWjMy1pGrO3a37zDrBV18SfHjLW1yTbmeUu7WtGVGUzuGvqfrWu+Pw+D1u9fRNg37cCNtG4qf5+j3L7CzMe1ijD5/KT6hnL3ChMe4D2eUyTc5+Cjgiq5t6UXP/gQ0htEmSjH3IWZ2QDk/pl+eYsOA7vKEaiGw+RHNHp58pev5AxTI/0BXloxUptGrCwGOBRgI8ERSTTzQLQIBBtSLI0r15V/g3wlFz8DC1BR3U7nuG5+mXwaS8FCAgSSciAKa8DUuuqIfkIO1pOv97HppDL4eBNr5hxnZqpeEIcX0EDs/pV2eUVwE3u18n/YUFx6g5cGapqe0OXVpaKuSSrwRr538EqtlT2aoi5wii4lXn9jcfkTdf2b9/Qol632dRzfo+IY0WqHzlEw9eOmdIok33p96Rhf8AvM4TBtRF/DeAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "memory-php",
              "title": "memory-php",
              "src": "/static/58516bc8558821dd71267f1be82caed6/8ca47/memory-php.png",
              "srcSet": ["/static/58516bc8558821dd71267f1be82caed6/7fc1e/memory-php.png 288w", "/static/58516bc8558821dd71267f1be82caed6/a5df1/memory-php.png 576w", "/static/58516bc8558821dd71267f1be82caed6/8ca47/memory-php.png 975w"],
              "sizes": "(max-width: 975px) 100vw, 975px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <p>{`If the speed improvements weren’t enough, you will be very impressed with the reduced memory usage per request. PHP 7 requires between one half and one eighth the memory per request as an application running on PHP 5, according to `}<a parentName="p" {...{
            "href": "https://badoo.com/techblog/blog/2016/03/14/how-badoo-saved-one-million-dollars-switching-to-php7/"
          }}>{`a company with a large existing PHP 5 application.`}</a>{` They calculated that it saved them over a million dollars in the first year.`}</p>
        <h3>{`3. PHP 7 lets you catch bugs earlier`}</h3>
        <p>{`PHP is somewhat famous for being easy to mess up. It gives you a lot of power; if programmers are not careful, they can create bugs that are difficult and costly to debug and potentially cause major security problems. PHP 7 makes it much harder to screw up, by `}<a parentName="p" {...{
            "href": "https://www.php.net/manual/en/migration70.new-features.php"
          }}>{`including strict type hinting`}</a>{` and removing old, dangerous functions. Once you’ve upgraded, programmers can sprinkle these type hints throughout the codebase and catch bugs they never knew existed.`}</p>
        <h3>{`4. Caching has been significantly improved`}</h3>
        <p>{`Caching makes frequently-used pages load faster. In PHP 7, the caching facilities are even better than they were in PHP 5.5. You can `}<a parentName="p" {...{
            "href": "https://www.php.net/manual/en/opcache.configuration.php"
          }}>{`configure PHP’s caching`}</a>{` to tailor its behavior to your specific site. `}</p>
        <h3>{`5. Improved error handling means fewer bugs crash your site`}</h3>
        <p>{`Previously, many kinds of errors were fatal, meaning that they stopped execution of the PHP program and caused the visitor to see a 500 page. Now, `}<a parentName="p" {...{
            "href": "https://www.php.net/manual/en/language.errors.php7.php"
          }}>{`many of these errors are more easily detected and can be recovered from`}</a>{`. This way, you can display custom error messages in more situations and recover gracefully from a variety of problems.`}</p>
        <h3>{`6. 64-bit numbers for higher precision and bigger values`}</h3>
        <p>{`PHP 7 `}<a parentName="p" {...{
            "href": "https://www.php.net/manual/en/language.types.integer.php"
          }}>{`switched the default number size on all platforms to 64 bits`}</a>{`. This means that you can deal with larger numbers without strange bugs caused by integer overflow on some platforms. The precision of calculations involving very large or very small numbers is also much better. `}</p>
        <h3>{`7. PHP 5.6 is no longer officially supported`}</h3>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.208333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB6klEQVQoz3VTyW7UQBD1D3LkwleABAeO/AEXhEAKSBARBZBACGVRwgUQkSYZMgmBmfHS3sZ72+2l7chweHS38GhQJoen51d2varuKmthksH2fLiuC8/zQClFVVUKdV0rLsvyir4OWumbKAIHqTBaLHxkWaaSJEdRhDRNlZYstSw4GK+D9v3zQ0yOXsJxQhj6DLPpFLZtQ9d1GIahIDuX2jRNzOdzVWwwlbwK7Zt3hrE7RZQUCLMcCWXIc4o4jlRiHMdIkkRh6DLPczRN8981LDsswxgZ0dFYp6icC/DIAi25QIOiyJU5G5gxESsUpOm6LjU83sCPo1fY3bsDY+sm8s0b6H7uo/F1tMKYVS3K9jdyVqFYMZTmaw2jj49gur8wCmf4MN3F+8kWdvbu4sv+fZifHsA/eYF6dgAWzFHSREySgf2b6LrhaKfhuTgSRV+34FWDUgSnNMSxdYyd8Ta+jjdxPnqKfPQc3eQtyiITZtX1hmlRg3Y9GG/FRdfg4rL79lIU4OjFcdvuD+oeaHin3slhcM6vTHd55DOxMoevb+Pi5B1sxwexLLUeFiEwiXw2YFsmiNCE2Iot8Y1cpSAI1F7KAQ2s8e0nuLx3C8WbZ7CCEI5KJGoXJciKdhxnGfd9X/wICwVpLLVcsb+yhSfwMONn1QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "currently-supported-versions",
              "title": "currently-supported-versions",
              "src": "/static/1be0d1fcb2c22e4c45c0d0f9ddc807a1/3cbba/currently-supported-versions.png",
              "srcSet": ["/static/1be0d1fcb2c22e4c45c0d0f9ddc807a1/7fc1e/currently-supported-versions.png 288w", "/static/1be0d1fcb2c22e4c45c0d0f9ddc807a1/a5df1/currently-supported-versions.png 576w", "/static/1be0d1fcb2c22e4c45c0d0f9ddc807a1/3cbba/currently-supported-versions.png 1152w", "/static/1be0d1fcb2c22e4c45c0d0f9ddc807a1/ea0fa/currently-supported-versions.png 1354w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <p>{`Although there is still a wealth of information online about PHP 5, it is `}<a parentName="p" {...{
            "href": "https://www.php.net/supported-versions.php"
          }}>{`no longer in active support`}</a>{`. In the future, it will be harder to keep PHP 5 applications running, since security patches and bug fixes have been stopped for multiple years now. `}</p>
        <h3>{`8. Many hosting providers only offer PHP 7 support`}</h3>
        <p>{`In many cases, it’s more practical to run PHP applications on a hosting provider’s infrastructure instead of your own. As PHP 5 gets longer in the tooth, fewer and fewer hosting providers continue to offer it as an option. `}</p>
        <h3>{`Conclusion`}</h3>
        <p>{`Switching to PHP 7 is extremely important. It can result in huge cost savings, both in hosting costs and in developer time. If you don’t upgrade your application to PHP 7 very soon, your hosting provider might not be able to continue hosting your application. Plus, the lack of security fixes for PHP 5 means that you are much more vulnerable to hackers. Over time, all of these issues will get even worse as PHP 5 gets older and older. `}</p>
        <Share mdxType="Share" />
      </Column>
    </Row>
    <hr></hr>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={3} colLg={4} mdxType="Column">
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and we’ll help you plan your route. Our team will contact you for more information about your requirements and a detailed analysis of your needs. `}</p>
        <p>{`We will use your information to make a detailed evaluation proposal available to you.`}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      